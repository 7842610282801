"use client";

import { MixpanelContext } from "@src/mixpanel/context";
import mixpanel, { OverridedMixpanel } from "mixpanel-browser";
import { Session } from "next-auth";
import { useCallback, useEffect, useMemo, useState } from "react";

interface MixpanelProviderProps {
  token: string;
  session: Session | null;
  children?: React.ReactNode;
}
export const MixpanelProvider: React.FC<MixpanelProviderProps> = ({
  children,
  token,
  session,
}) => {
  const [mixPanelInstance, setMixPanelInstance] =
    useState<OverridedMixpanel | null>(null);

  useEffect(() => {
    const initMixpanel = async () => {
      if (!token) {
        return;
      }
      if (mixPanelInstance) {
        return;
      } else if (token && !mixPanelInstance) {
        mixpanel.init(token);
        setMixPanelInstance(mixpanel);
      }
    };

    initMixpanel();
  }, [mixPanelInstance, token]);

  const reset = useCallback(() => {
    if (mixPanelInstance) {
      mixPanelInstance.reset();
    }
  }, [mixPanelInstance]);

  const track = useCallback(
    (eventName: string, properties?: object) => {
      if (!mixPanelInstance) {
        return;
      }

      if (session) {
        mixPanelInstance.identify(session.user.id);
        mixPanelInstance.people.set({
          ...session.user,
          $id: session.user.id,
          $first_name: session.user.name,
          $email: session.user.email,
        });
      }
      mixPanelInstance.track(eventName, properties);
    },
    [mixPanelInstance, session]
  );

  const contextValue = useMemo(
    () => ({
      mixpanel: mixPanelInstance,
      track,
      reset,
    }),
    [mixPanelInstance, track, reset]
  );

  return (
    <MixpanelContext.Provider value={contextValue}>
      {children}
    </MixpanelContext.Provider>
  );
};
