import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback, useMemo } from "react";
import { z } from "zod";

export const useSearch = <T extends Record<string, any>>(
  schema?: z.ZodType<T, any, any>
) => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();

  const search = useMemo(() => {
    const searchObject = searchParams
      ? Object.fromEntries(searchParams.entries())
      : {};

    return schema ? schema.parse(searchObject) : (searchObject as T);
  }, [schema, searchParams]);

  return [
    search,
    useCallback(
      (params: {
        [K in keyof T]?: T[K] | null;
      }) => {
        const newParams = new URLSearchParams();

        for (const [key, value] of Object.entries({ ...search, ...params })) {
          if (value === null) {
            newParams.delete(key);
          } else if (value !== undefined) {
            newParams.set(key, value);
          }
        }

        router.push(`${pathname}?${newParams.toString()}`);
      },
      [pathname, router, search]
    ),
  ] as const;
};
