"use client";

import React from "react";
import { Text } from "./Typography";

interface Props {
  Fallback?: React.ComponentType<{
    error: Error;
    onReset: () => void;
  }>;
  children: React.ReactNode;
}

interface State {
  error: Error | null;
}

export class ErrorHandler extends Error {
  resolve: () => Promise<void>;

  constructor(message: string, resolve: () => Promise<void>) {
    super(message);
    this.name = "ErrorHandler";
    this.resolve = resolve;
  }
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = { error: null };

  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can use your own error logging service here
    console.error(error);

    if (error instanceof ErrorHandler) {
      error.resolve().then(
        () => {
          this.setState({ error: null });
        },
        () => this.setState({ error })
      );
    }
  }

  render() {
    if (this.state.error) {
      const Fallback =
        this.props.Fallback ??
        (({ error }) => (
          <Text css="bg-rose-500 text-rose-50 p-4">{error.message}</Text>
        ));

      return (
        <Fallback
          error={this.state.error}
          onReset={() => this.setState({ error: null })}
        />
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
