"use client";

import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { CheckIcon } from "@reframe.so/icons/icons/CheckIcon";

import { makeComponent, t, tw, v } from "./tailwind";

const Indicator = tw(
  CheckboxPrimitive.Indicator,
  t`text-primary-700 flex items-center justify-center`
);

const checkbox = v({
  base: t`peer h-5 w-5 shrink-0 rounded-sm border border-neutral-400/40 ring-offset-primary-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-700 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-primary-300`,
  variants: {},
});

export const Checkbox = makeComponent({
  as: CheckboxPrimitive.Root,
  from: checkbox,
  name: "Checkbox",
  useOverride() {
    return {
      children: (
        <Indicator>
          <CheckIcon css="h-4 w-4 stroke-2" />
        </Indicator>
      ),
    };
  },
});
