"use client";
import { createContext, useContext } from "react";
import { OverridedMixpanel } from "mixpanel-browser";

export interface MixpanelContextType {
  mixpanel: OverridedMixpanel | null;
  track: (eventName: string, properties?: object) => void;
  reset: () => void;
}

export const MixpanelContext = createContext<MixpanelContextType | undefined>(
  undefined
);

export const useMixpanel = (): MixpanelContextType => {
  const context = useContext(MixpanelContext);
  if (!context) {
    throw new Error("useMixpanel must be used within a MixpanelProvider");
  }

  return context;
};
