"use client";

import { ErrorBoundary, Toast } from "@reframe.so/blocks";

import { Indicator } from "@reframe.so/blocks/src/Indicator";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { ClickToComponent as Devtools } from "@reframe.so/devtools";

import { useSession } from "next-auth/react";
import { useEffect } from "react";
import { Analytics } from "@vercel/analytics/react";
import { usePathname } from "next/navigation";
import Script from "next/script";

import { useSearch } from "@src/hooks/search";
import { storeRefCookie } from "@src/resync/mutation";
import { NotWhiteLabel } from "@src/components/whitelabel";
import { MixpanelProvider } from "@src/mixpanel/provider";

declare global {
  interface Window {
    fbq?: Function;
  }
}

const isProduction = process.env.NODE_ENV === "production";
const isBrowser = typeof window !== "undefined";
const isPixelEnabled = isProduction && isBrowser;
const enabled = isPixelEnabled && window.location.pathname !== "/";

const CrispChat = ({ id }: { id: string }) => {
  const pathname = usePathname();
  const { data } = useSession();
  const user = data?.user;

  if (
    pathname?.includes("share") ||
    pathname?.includes("recipe") ||
    pathname?.includes("live-chat") ||
    pathname?.includes("admin") ||
    pathname?.startsWith("/hook/") ||
    pathname?.includes("/beta/preview") ||
    typeof window === "undefined" ||
    window.self !== window.top
  ) {
    return null;
  }

  if (!user) {
    return (
      <Script
        src="https://app.10xlaunch.ai/widget"
        data-app-id="40aaabe0-141f-431e-8789-8ff6a5a20046"
        async
        defer
      ></Script>
    );
  } else if (
    user?.plan === null ||
    user?.plan === "free:lifetime" ||
    user?.plan === "free:monthly" ||
    user?.plan === "free:yearly"
  ) {
    return (
      <Script
        src="https://app.10xlaunch.ai/widget"
        data-app-id="40aaabe0-141f-431e-8789-8ff6a5a20046"
        async
        defer
      ></Script>
    );
  } else {
    return null;
  }
};

const GooglePixel = ({ pixelId }: { pixelId: string }) => {
  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${pixelId}`}
      />
      <Script
        id="google-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
   window.dataLayer = window.dataLayer || [];
   function gtag(){dataLayer.push(arguments);}
   gtag('js', new Date());
 
   gtag('config', "${pixelId}");
   `,
        }}
      />
    </>
  );
};

const FacebookPixel = ({ pixelId }: { pixelId: string }) => {
  useEffect(() => {
    window.fbq?.("track", "PageView");
  }, []);

  return (
    <Script
      id="pixel"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${pixelId});
        fbq('track', 'PageView');    
       `,
      }}
    />
  );
};

function ThirdParty() {
  return (
    <>
      <ErrorBoundary
        Fallback={({ error }) => {
          console.warn(error);

          return null;
        }}
      >
        {isProduction && <Analytics />}
      </ErrorBoundary>
      <NotWhiteLabel>
        <CrispChat id="afbc85c2-c020-45f7-81ee-1a88874e7f77" />
      </NotWhiteLabel>
      {/* <TawkChat id="f2dfdde607b3ab089dd20dbce6f232e3ada5fb5e" /> */}
      {/* {isPixelEnabled && <FacebookPixel pixelId="949081726449322" />}
      {isPixelEnabled && <GooglePixel pixelId="AW-11146895353" />} */}
    </>
  );
}

function CaptureReferrer() {
  const [search] = useSearch<{ ref?: string; "ref-chat"?: string }>();

  const ref = search.ref;
  const refChat = search["ref-chat"];

  useEffect(() => {
    if (ref) {
      storeRefCookie({ code: ref });
    } else if (refChat) {
      storeRefCookie({ chat: refChat });
    }
  }, [ref, refChat]);

  return null;
}

export function AppProvider({
  session,
  children,
}: {
  children: React.ReactNode;
  session: Session | null;
}) {
  const token = process.env.NEXT_PUBLIC_MIXPANEL_API_KEY!;

  return (
    <SessionProvider session={session}>
      <MixpanelProvider token={token} session={session}>
        <CaptureReferrer />
        <Devtools
          editor={(process.env.NEXT_PUBLIC_DEV_EDITOR as "vscode") || "vscode"}
        />
        <Indicator />
        {children}
        <Toast.Provider />
        <ThirdParty />
      </MixpanelProvider>
    </SessionProvider>
  );
}
