"use client";

import * as Primitive from "@radix-ui/react-scroll-area";
import { makeComponent, t, tw, v } from "./tailwind";
import React, { ComponentProps } from "react";

// future: add a prop, `pinToBottom`, that will pin the scroll area to the bottom
// https://css-tricks.com/books/greatest-css-tricks/pin-scrolling-to-bottom/

const Viewport = tw(
  Primitive.Viewport,
  t`absolute inset-0 rounded-[inherit] child/viewport [&>div]:!block`
);

const Corner = tw(Primitive.Corner, t``);

const ScrollAreaThumb = tw(
  Primitive.ScrollAreaThumb,
  t`relative flex-1 rounded-full bg-neutral-600/30`
);

const Scrollbar = tw(
  Primitive.ScrollAreaScrollbar,
  t`flex touch-none select-none transition-colors h-full w-2.5 border-l border-l-transparent p-px`
);

export const ScrollArea = makeComponent({
  as: Primitive.Root,
  from: v({
    base: t`relative overflow-hidden h-full grow`,
  }),
  useOverride(
    {
      type = "scroll",
      children,
    }: {
      children: React.ReactNode;
      type?: ComponentProps<typeof Primitive.Root>["type"];
    },
    ref
  ) {
    return {
      type,
      children: (
        <>
          <Viewport>{children}</Viewport>
          <Scrollbar>
            <ScrollAreaThumb />
          </Scrollbar>
          <Corner />
        </>
      ),
    };
  },
});
