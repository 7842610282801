"use client";

import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

import { MakeProps, makeComponent, t, v } from "./tailwind";

const textarea = v({
  base: t`flex h-20 w-full rounded-md bg-transparent px-3 py-2 text-sm placeholder:text-neutral-400 disabled:cursor-not-allowed disabled:opacity-50 scrollbar-none text-neutral-700 min-h-[32px]`,
  variants: {
    autoHeight: {
      true: t`resize-none`,
      false: t``,
    },
    bordered: {
      false: t`border-0`,
      true: t`border border-neutral-400/50`,
    },
    outline: {
      false: t`ring-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0`,
      true: t`ring-offset-primary-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-300 focus-visible:ring-offset-2`,
    },
  },
  defaultVariants: {
    autoHeight: true,
    outline: true,
    bordered: true,
  },
});

export type TextareaProps = MakeProps<typeof textarea, "textarea">;

export const Textarea = makeComponent({
  from: textarea,
  as: "textarea",
  name: "Textarea",
  useOverride: ({ autoHeight, value, onChange, onInput }, ref) => {
    const cursor = useRef<null | number>(null);
    const inputRef = useRef<HTMLTextAreaElement | null>(null);

    useImperativeHandle(ref, () => inputRef.current!);

    const adjustHeight = useCallback(() => {
      if (autoHeight !== false) {
        const input = inputRef.current;
        if (input) {
          input.style.height = "auto";
          input.style.height = `${input.scrollHeight}px`;
        }
      }
    }, [autoHeight]);

    useEffect(() => {
      adjustHeight();
    }, [adjustHeight, autoHeight, value]);

    // useEffect(() => {
    //   const input = inputRef.current;

    //   if (input && cursor.current) {
    //     input.setSelectionRange(cursor.current, cursor.current);
    //   }
    // }, [cursor, value]);

    return {
      ref: inputRef,
      onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        cursor.current = e.target.selectionStart;
        adjustHeight();
        onChange?.(e);
      },
      onInput: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        adjustHeight();
        onInput?.(e);
      },
     
    };
  },
});
