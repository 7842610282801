"use client";

import { brandAtom } from "@src/resync/hydrate/client";

export const NotWhiteLabel: React.ComponentType<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const { brand } = brandAtom.useValue();

  if (!brand) {
    return <>{children}</>;
  } else {
    return null;
  }
};
