"use client";

import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { makeComponent, t, tw, v } from "./tailwind";
import { ChevronDownIcon } from "@reframe.so/icons/icons/ChevronDownIcon";

const Root = tw(AccordionPrimitive.Root, t``);

const Header = tw(AccordionPrimitive.Header, t`flex`);

const Trigger = tw(
  AccordionPrimitive.Trigger,
  t`flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180`
);

const Content = makeComponent({
  as: AccordionPrimitive.Content,
  from: v({
    base: t`data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden text-sm transition-all`,
  }),
  useOverride(props, ref) {
    return {
      children: <div className="pb-4 pt-0">{props.children}</div>,
    };
  },
});

const Item = makeComponent({
  as: AccordionPrimitive.Item,
  from: v({}),
  useOverride(
    props: {
      trigger: React.ReactNode;
      children: React.ReactNode;
      value?: string;
    },
    ref
  ) {
    return {
      value: props.value ?? "none",
      children: (
        <>
          <Header>
            <Trigger>
              {props.trigger}
              <ChevronDownIcon css="h-4 w-4 transition-transform duration-200" />
            </Trigger>
          </Header>
          <Content>{props.children}</Content>
        </>
      ),
    };
  },
});

export const Accordion = Object.assign(Root, {
  Item,
});

export const AccordionItem = Item;
