"use client";

import { FloatingPortal } from "@floating-ui/react-dom-interactions";
import * as React from "react";

import { ContextMenu } from "./ContextMenu";
import { getPathToSource, getSourceForElement } from "./helpers";

type State = "idle" | "hover" | "select";

export const ClickToComponent: React.ComponentType<{
  editor: "vscode" | "vscode-insiders" | "cursor";
}> = ({ editor }) => {
  const [state, setState] = React.useState<State>("idle");

  const [target, setTarget] = React.useState<HTMLElement | null>(null);

  const onClick = React.useCallback(
    (event: MouseEvent) => {
      if (state === "hover" && target instanceof HTMLElement) {
        const source = getSourceForElement(target);

        if (!source) {
          return;
        }

        const path = getPathToSource(source);
        const url = `${editor}://file/${path}`;

        // console.log("open", url);
        // event.preventDefault();
        // window.location.assign(url);

        setState("idle");
      }
    },
    [editor, state, target]
  );

  const onClose = React.useCallback(
    (returnValue: unknown) => {
      if (returnValue) {
        const url = `${editor}://file/${returnValue}`;
        window.location.assign(url);
      }

      setState("idle");
    },
    [editor]
  );

  const onContextMenu = React.useCallback(
    (event: MouseEvent) => {
      const { target } = event;

      if (state === "hover" && target instanceof HTMLElement) {
        event.preventDefault();

        setState("select");
        setTarget(target);
      }
    },
    [state]
  );

  const onKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      switch (state) {
        case "idle":
          if (event.altKey) {
            setState("hover");
          }
          break;

        default:
      }
    },
    [state]
  );

  const onKeyUp = React.useCallback(
    (event: KeyboardEvent) => {
      switch (state) {
        case "hover":
          setState("idle");
          break;

        default:
      }
    },
    [state]
  );

  const onMouseMove = React.useCallback(
    (event: MouseEvent) => {
      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      switch (state) {
        case "idle":
        case "hover":
          setTarget(event.target);
          break;

        default:
          break;
      }
    },
    [state]
  );

  React.useEffect(() => {
    for (const element of Array.from(
      document.querySelectorAll("[data-click-to-component-target]")
    )) {
      if (element instanceof HTMLElement) {
        delete element.dataset.clickToComponentTarget;
      }
    }

    if (state === "idle") {
      delete window.document.body.dataset.clickToComponent;

      if (target) {
        delete target.dataset.clickToComponentTarget;
      }

      return;
    }

    if (target instanceof HTMLElement) {
      window.document.body.dataset.clickToComponent = state;
      target.dataset.clickToComponentTarget = state;
    }
  }, [state, target]);

  React.useEffect(() => {
    window.addEventListener("click", onClick, { capture: true });
    window.addEventListener("contextmenu", onContextMenu, { capture: true });
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    window.addEventListener("mousemove", onMouseMove);

    return function removeEventListenersFromWindow() {
      window.removeEventListener("click", onClick, { capture: true });
      window.removeEventListener("contextmenu", onContextMenu, {
        capture: true,
      });
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, [onClick, onContextMenu, onKeyDown, onKeyUp, onMouseMove]);

  return (
    <>
      <style key="click-to-component-style">
        {`[data-click-to-component] * {
        pointer-events: auto !important;
      }

      [data-click-to-component-target] {
        cursor: var(--click-to-component-cursor, context-menu) !important;
        outline: auto 1px;
        outline: var(
          --click-to-component-outline,
          -webkit-focus-ring-color auto 1px
        ) !important;
      }`}
      </style>

      <FloatingPortal key="click-to-component-portal">
        <ContextMenu key="click-to-component-contextmenu" onClose={onClose} />
      </FloatingPortal>
    </>
  );
};
