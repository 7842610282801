"use client";

import * as PopoverPrimitive from "@radix-ui/react-popover";
import { makeComponent, t, tw, v, CSS } from "./tailwind";

const Trigger = tw(PopoverPrimitive.Trigger, t``);

const Content = tw(
  PopoverPrimitive.Content,
  t`bg-white z-50 w-72 rounded-md border p-4 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2`
);

const Portal = tw(PopoverPrimitive.Portal, t``);

export const Popover = makeComponent({
  from: v({
    base: t``,
  }),
  as: PopoverPrimitive.Root,
  name: "Popover",
  useOverride({
    children,
    align = "center",
    side = "bottom",
    sideOffset = 4,
    css,
    content,
  }: {
    children: React.ReactNode;
    content: React.ReactNode;
    align?: "center" | "start" | "end";
    side?: "top" | "bottom" | "left" | "right";
    sideOffset?: number;
    css?: CSS;
  }) {
    return {
      children: (
        <>
          <Trigger asChild>{children}</Trigger>
          <Portal>
            <Content align={align} sideOffset={sideOffset} css={css}>
              {content}
            </Content>
          </Portal>
        </>
      ),
      css: t``,
    };
  },
});
